import Footer from "./footer"
import Header from "./header"
import Layout from "./layout"
import DownloadFrom from "./downloadFrom"
import SMI from "./smi"
import FaqCard from "./faqCard"
import Filter from "./filter"
import Search from "./search"
import FaqModal from "./faqModal"
import SEO from "./seo"

export {
  SEO,
  Footer,
  Header,
  Layout,
  DownloadFrom,
  SMI,
  FaqCard,
  Filter,
  Search,
  FaqModal,
}
