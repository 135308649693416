import React, { Component } from "react"

import Menu from "./menu"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import "tachyons"

import TagManager from 'react-gtm-module';

export default class Layout extends Component {
  state = {
    showMenu: false,
  }

  componentDidMount() {
    const tagManagerArgs = {
      gtmId: 'GTM-MX2GFL3'
    }
    
    TagManager.initialize(tagManagerArgs);
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  render() {
    return (
      <React.Fragment>
        {this.props.version != "order" ? 
          (<Header
            headerColor={this.props.headerColor}
            onClick={this.toggleMenu}
            showMenu={this.state.showMenu}
            version={this.props.version}
          />) : null
        }

        {this.state.showMenu ? (
          <Menu toggleMenu={this.toggleMenu} version={this.props.version} />
        ) : null}
        <main
          id="main"
          className="lh-copy"
          style={
            this.state.showMenu ? { display: "none" } : { display: "block" }
          }
        >
          {this.props.children}
        </main>

        {this.props.version != "order" ? 
          (<Footer version={this.props.version} showMenu={this.state.showMenu} />)
        : null }
      </React.Fragment>
    )
  }
}
