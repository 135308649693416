import React, { Component } from "react"
import ReactDOM from "react-dom"
import ReactMarkdown from "react-markdown"

import { close } from "../assets"
import "./faqModal.css"
import "./header.css"

export default class FaqModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slide: "slide_in",
      fade: "fade_in",
    }
    this.el = document.createElement("div")
  }

  componentDidMount() {
    const gatsbyRoot = document.getElementById("___gatsby")
    gatsbyRoot.insertBefore(this.el, gatsbyRoot.childNodes[0])
  }

  componentWillUnmount() {
    const gatsbyRoot = document.getElementById("___gatsby")
    gatsbyRoot.removeChild(this.el)
  }

  changeSlideStyle = async () => {
    await this.setState({ slide: "slide_out", fade: "fade_out" })
    setTimeout(
      function() {
        this.props.onClose()
      }.bind(this),
      450
    )
  }

  render() {
    return ReactDOM.createPortal(
      <div
        className={
          "z-max flex justify-end faq__modal__wrapper lh-copy " +
          this.state.fade
        }
      >
        <div className="w-50 h-100 db-l dn" onClick={this.changeSlideStyle} />
        <div className={"faq__modal__style " + this.state.slide}>
          <img
            alt="close"
            title="close"
            src={close}
            onClick={this.changeSlideStyle}
            className="  mt2 close link dim"
          />
          <h1 className="f1-l f2-m f3 ipad__font__header">
            {this.props.header}
          </h1>
          <ReactMarkdown source={this.props.text} />

          <div className="faq__modal__line" />
          <div className="flex flex-row-l flex-column  flex__direction mb5-ns mb3 ipad__bottom__component">
            <div className="mr3">
              <h4 className="mb1 ipad__font__text">
                Для Вас ответ оказался полезным?
              </h4>
              <span className="gray f6">95% считают полезным</span>
            </div>
            <div className="flex flex-row mb0-l mb4 buttons__ipad">
              <a
                className="f6 link dim ba ph5-ns pv3-ns pv2 ph5 dib bg-yellow faq__modal__button mr3"
                href="#0"
              >
                Полезно
              </a>
              <a
                className="f6 link dim ba ph4-ns pv3-ns pv2 ph4 dib faq__modal__button"
                href="#0"
              >
                Нет
              </a>
            </div>
          </div>
        </div>
      </div>,
      this.el
    )
  }
}
