import React from "react"
import { logo, menu, close, menuWhite, okAutoLogoNew, okAutoLogoNewBlack, whatsAppWhite } from "../assets"
import "tachyons"
import "./header.css"

const Header = ({ onClick, showMenu, headerColor, version }) => {
  if (version === "business") {
    return (
      <nav id="header"
           className="z-999 flex flex-row justify-between items-center absolute top-0 w-100 border-box pv4-ns pv3 ph5-ns ph3 bg-transparent"
      >
        {!showMenu ? (
        <a href="/" className=" link dim tl ">
          <img
            alt="logo"
            title="logo"
            src={okAutoLogoNew}
            className="br2 mb0 header__logo header__logo--new"
          />
        </a>) : (
        <a href="/" className=" link dim tl ">
          <img
            alt="logo"
            title="logo"
            src={okAutoLogoNewBlack}
            className="br2 mb0 header__logo header__logo--new"
          />
        </a>
          )
        }
        <div className="flex-grow-1 pr4-l pr3">
          <a
            className="link white flex items-center fr"
            href="https://api.whatsapp.com/send?phone=77075001122&text=Добрый%20день,%20у%20меня%20есть%20вопросы%20по%20b2b%20сервису."
            target="_blank"
          >
            <span className="db-l dn mr3 b">Написать в WhatsApp</span>
            <img
              alt="wp"
              title="wp"
              src={whatsAppWhite}
              className="w1 h1 w2-l h2-l ma0"
            />
          </a>
        </div>
        <div className="link dim tr ">
          {!showMenu ? (
            <img
              alt="hamburger"
              title="hamburger"
              src={headerColor ? menuWhite : menu}
              className="mb0 mt2 hamburger"
              onClick={onClick}
            />
          ) : (
            <img
              alt="close"
              title="close"
              src={close}
              className=" mb0 mt2 close"
              onClick={onClick}
            />
          )}
        </div>
      </nav>
    )
  } else {
    return (
      <nav
        id="header"
        className="z-999 flex flex-row justify-between items-center absolute top-0 w-100 border-box pv4-ns pv3 ph5-ns ph3 bg-transparent"
      >
        <a href="/" className=" link dim tl ">
          <img
            alt="logo"
            title="logo"
            src={logo}
            className="br2 mb0 header__logo"
          />
        </a>
        {!showMenu ? (
          <div className="flex flex-row center  tc">
            <a
              href="/about"
              className="link dim f4-ns f5 dn dib-ns pr5"
              style={headerColor ? { color: "white" } : { color: "#37474f" }}
            >
              О проекте
            </a>
    
            <a
              href="/business"
              className="link dim f4-ns f5 dib underline no-underline-ns ml5-ns"
              style={headerColor ? { color: "white" } : { color: "#37474f" }}
            >
              Бизнесу
            </a>
          </div>
        ) : null}
    
        <div className="link dim tr ">
          {!showMenu ? (
            <img
              alt="hamburger"
              title="hamburger"
              src={headerColor ? menuWhite : menu}
              className="mb0 mt2 hamburger"
              onClick={onClick}
            />
          ) : (
            <img
              alt="close"
              title="close"
              src={close}
              className=" mb0 mt2 close"
              onClick={onClick}
            />
          )}
        </div>
      </nav>
    )
  }
}

export default Header
