import React from "react"
import "./search.css"
const Search = ({ value, searchFilter }) => {
  return (
    <div className="search__container flex">
      <input
        type="text"
        placeholder="Ваш вопрос"
        onChange={searchFilter}
        value={value}
        onSubmit={searchFilter}
        className="db search__box"
      />

      <a className="link dim dib white button__wrapper">Найти</a>
    </div>
  )
}

export default Search
