import React from "react"
import "./filter.css"

const active = "filter__selector active__type"
const notActive = "filter__selector"

const Filter = ({ changeFilter, currentFilter }) => {
  return (
    <ul className="flex list__wrapper">
      <li>
        <button
          className={currentFilter === "ALL" ? active : notActive}
          onClick={() => changeFilter("ALL")}
        >
          Все
        </button>
      </li>
      <li>
        <button
          className={currentFilter === "PENALTY" ? active : notActive}
          onClick={() => changeFilter("PENALTY")}
        >
          Штрафы
        </button>
      </li>
      <li>
        <button
          className={currentFilter === "PAYMENT" ? active : notActive}
          onClick={() => changeFilter("PAYMENT")}
        >
          Оплата
        </button>
      </li>
      <li>
        <button
          className={currentFilter === "TECHSERVICE" ? active : notActive}
          onClick={() => changeFilter("TECHSERVICE")}
        >
          Техпомощь
        </button>
      </li>
      <li>
        <button
          className={currentFilter === "INSURANCE" ? active : notActive}
          onClick={() => changeFilter("INSURANCE")}
        >
          Страховка
        </button>
      </li>
    </ul>
  )
}

export default Filter
