import React from "react"
import "tachyons"
import { vk, wp, yb, ig, fb, ios, ad,
         vkBlack, wpBlack, ybBlack,
        igBlack, fbBlack, whatsAppBtn } from "../assets"

const Footer = ({version, showMenu}) => {
  if (version === "business" && !showMenu) {
    return (
      <footer className="pv3 ph5 relative" style={{ backgroundColor: "#ffd503" }}>
        <div className="tl-l tc mb2">
          <a
            className="dib v-mid"
            href="https://www.instagram.com/okauto.kz/"
            target="_blank"
          >
            <img
              alt="ig"
              title="ig"
              src={igBlack}
              className="w1 h1 ml2 mr2 mb0"
            />
          </a>
          <a
            className="dib v-mid"
            href="https://www.facebook.com/okauto.kz/"
            target="_blank"
          >
            <img
              alt="fb"
              title="fb"
              src={fbBlack}
              className="w1 h1 ml2 mr2 mb0"
            />
          </a>
          <a className="dib v-mid">
            <img
              alt="yb"
              title="yb"
              src={ybBlack}
              className="w1 h1 ml2 mr2 mb0"
            />
          </a>
          <a className="dib v-mid">
            <img
              alt="vk"
              title="vk"
              src={vkBlack}
              className="w1 h1 ml2 mr2 mb0"
            />
          </a>
          <a
            className="dib v-mid"
            href="https://api.whatsapp.com/send?phone=77075001122&text=Добрый%20день,%20у%20меня%20есть%20вопросы%20по%20b2b%20сервису."
            target="_blank"
          >
            <img
              alt="wp"
              title="wp"
              src={wpBlack}
              className="w1 h1 ml2 mr2 mb0"
            />
          </a>
        </div>
        <p className="tl-l tc f5-l f7 mb0">© {new Date().getFullYear()} OKauto</p>
        <a
            className="absolute w2 h2 w3-l h3-l right-2 bottom-2"
            href="https://api.whatsapp.com/send?phone=77075001122&text=Добрый%20день,%20у%20меня%20есть%20вопросы%20по%20b2b%20сервису."
            target="_blank"
          >
            <img
              alt="wp"
              title="wp"
              src={whatsAppBtn}
              className="w2 h2 w3-l h3-l"
            />
          </a>
      </footer>
    )
  } else {
    return (
      <footer className={ version === "business" && showMenu ?
        "dn fixed-ns  bottom-0  bg-white dt-l w-100 border-box pa3 ph3-m ph4-l ph4 z-max" :
        "fixed-ns  bottom-0  bg-white dt-ns w-100 border-box pa3 ph3-m ph4-l ph4 z-max"}
      >
        <div className="dtc-ns dn tl v-mid w-30-ns w-100 f6" style={color}>
          © {new Date().getFullYear()} OKauto
        </div>
        <p className="dn-ns dt tl v-mid w-100 f6 mt3">
          <a className="link" style={color}>
            <img
              alt="ios"
              title="ios"
              src={ios}
              className="w1 h1 mb0 mr2 relative top"
            />
            Для iOS
          </a>
          <a className="link" style={color}>
            <img
              alt="ad"
              title="ad"
              src={ad}
              className="w1 h1 ml4 mr2 relative top"
            />
            Для Android
          </a>
        </p>
        <div className="dtc-ns dt tc-ns tl v-mid w-40-ns w-100 relative-ns top-1-ns mb0-ns mt0-ns mb1 mt3">
          <a
            className="dib v-mid"
            href="https://www.instagram.com/okauto.kz/"
            target="_blank"
          >
            <img
              alt="ig"
              title="ig"
              src={ig}
              className="w1 h1 ml3-ns mr3-ns ml2-m mr2-m ml2 mr2"
            />
          </a>
          <a
            className="dib v-mid"
            href="https://www.facebook.com/okauto.kz/"
            target="_blank"
          >
            <img
              alt="fb"
              title="fb"
              src={fb}
              className="w1 h1 ml3-ns mr3-ns ml2-m mr2-m ml2 mr2"
            />
          </a>
          <a className="dib v-mid">
            <img
              alt="yb"
              title="yb"
              src={yb}
              className="w1 h1 ml3-ns mr3-ns ml2-m mr2-m ml2 mr2"
            />
          </a>
          <a className="dib v-mid">
            <img
              alt="vk"
              title="vk"
              src={vk}
              className="w1 h1 ml3-ns mr3-ns ml2-m mr2-m ml2 mr2"
            />
          </a>
          <a
            className="dib v-mid"
            href={ version === "business" ?
            "https://api.whatsapp.com/send?phone=77075001122&text=Добрый%20день,%20у%20меня%20есть%20вопросы%20по%20b2b%20сервису." :
            "https://api.whatsapp.com/send?phone=77075001122" }
            target="_blank"
          >
            <img
              alt="wp"
              title="wp"
              src={wp}
              className="w1 h1 ml3-ns mr3-ns ml2-m mr2-m ml2 mr2"
            />
          </a>
        </div>
        <p className="dtc-ns dn tr-ns tl v-mid w-30-ns w-100 f6">
          <a
            className="link"
            style={color}
            href="https://apps.apple.com/kz/app/okauto-%D1%88%D1%82%D1%80%D0%B0%D1%84%D1%8B-%D0%BF%D0%B4%D0%B4/id1218144486"
            target="_blank"
          >
            <img
              alt="ios"
              title="ios"
              src={ios}
              className="w1 h1 mb0 mr2 relative top"
            />
            Для iOS
          </a>
          <a
            className="link"
            style={color}
            href="https://play.google.com/store/apps/details?id=kz.psengineering.okauto"
            target="_blank"
          >
            <img
              alt="ad"
              title="ad"
              src={ad}
              className="w1 h1 ml4 mr2 relative top"
            />
            Для Android
          </a>
        </p>
        <div className=" dn-ns dt tl v-mid w-100 f6 mb3" style={color}>
          © {new Date().getFullYear()} OKauto
        </div>
      </footer>
    )
  }
}

const color = {
  color: "rgba(55, 71, 79, 0.6)",
}

export default Footer
