import React, { Component } from "react"
import ReactDOM from "react-dom"

import { wp, okLogoMenu, whatsAppGreen,
         igBlack, fbBlack, vkBlack, ybBlack } from "../assets"

import "./menu.css"

export default class Menu extends Component {
  constructor(props) {
    super(props)
    this.el = document.createElement("div")
  }

  componentDidMount() {
    const modalRoot = document.getElementById("___gatsby")

    modalRoot.firstChild.insertBefore(
      this.el,
      modalRoot.firstChild.childNodes[1]
    )
  }

  componentWillUnmount() {
    const modalRoot = document.getElementById("___gatsby")
    modalRoot.firstChild.removeChild(this.el)
  }

  render() {
    if (this.props.version === "business") {
      return ReactDOM.createPortal(
        <div className="flex flex-row-l flex-column justify-between center lh-copy menu__alignment menu__alignment--business">
          <div className="flex flex-column mb4">
            <h1 className="f2-l f5 ttu ttc-l silver b">Свяжитесь с нами</h1>
            <a
              href="mailto:help@okauto.kz"
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              help@okauto.kz
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=77075001122&text=Добрый%20день,%20у%20меня%20есть%20вопросы%20по%20b2b%20сервису."
              target="_blank"
              className="link hover-blue black pointer mv2 flex flex-row justify-start-l justify-between"
            >
              Напишите на Whatsapp
              <img alt="wp" title="wp" src={whatsAppGreen} className=" ml2" />
            </a>
            <a href="#contact__us" 
               className="link black pt1 pb1 b"
               onClick={this.props.toggleMenu}
            >
              <span style={{
                backgroundColor: "#ffd503",
                borderRadius: "32px",
                padding: "0.5rem"
              }}>
                Попробовать <span className="f7 lh-title normal">(бесплатно)</span>
              </span>
            </a>
          </div>
          <div className="flex flex-column mb4">
            <h1 className="f2-l f5 ttu ttc-l silver b">Информация</h1>
            <a
              href="/about"
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              О проекте
            </a>
            <a
              href="/faq"
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              Вопросы-ответы
            </a>
            <a
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              Приложение для частных клиентов 
            </a>
          </div>
          <div className="flex flex-column">
            <h2 className="f2-l f5 ttu ttc-l silver b">Навигация</h2>
            <a
              href="#control"
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              Контролируем автоштрафы
            </a>
            <a
              href="#approach"
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              Для кого мы работаем
            </a>
            <a
              href="#makeBetter"
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              Как улучшаем бизнес
            </a>
            <a
              href="#easyStart"
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              Как начать сотрудничество
            </a>
            <a
              href="#clients"
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              Наши клиенты
            </a>
            <a
              href="#calculator"
              className="link hover-blue black pointer mv2"
              onClick={this.props.toggleMenu}
            >
              Оценка стоимости и выгоды
            </a>
          </div>
          <div className="mt3 db dn-l">
            <a
              className="dib v-mid"
              href="https://www.instagram.com/okauto.kz/"
              target="_blank"
            >
              <img
                alt="ig"
                title="ig"
                src={igBlack}
                className="w1 h1 ml2 mr2"
              />
            </a>
            <a
              className="dib v-mid"
              href="https://www.facebook.com/okauto.kz/"
              target="_blank"
            >
              <img
                alt="fb"
                title="fb"
                src={fbBlack}
                className="w1 h1 ml2 mr2"
              />
            </a>
            <a className="dib v-mid">
              <img
                alt="yb"
                title="yb"
                src={ybBlack}
                className="w1 h1 ml2 mr2"
              />
            </a>
            <a className="dib v-mid">
              <img
                alt="vk"
                title="vk"
                src={vkBlack}
                className="w1 h1 ml2 mr2"
              />
            </a>
          </div>
        </div>,
        this.el
      )
    } else 
    return ReactDOM.createPortal(
      <div className="flex flex-row-ns flex-column justify-between center lh-copy menu__alignment">
        <div className="flex flex-column ">
          <img
            alt="okLogoMenu"
            title="okLogoMenu"
            src={okLogoMenu}
            className="self-start menu__logo"
          />
          <a
            href="/about"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            О проекте
          </a>
          <a
            href="/#checkSection"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Проверка и оплата
          </a>
          <a
            href="/#paySection"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Оплата со скидкой
          </a>
          <a
            href="/#newSection"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Уведомления
          </a>
          <a
            href="/photo"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Фото и место нарушения
          </a>
          <a
            href="/business"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Бизнесу
          </a>
          <a
            href="/#smiSection"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Мы в СМИ
          </a>
        </div>
        <div className="flex flex-column mt0-ns mt4">
          <h1 className="f1-l f2 b">Помощь</h1>
          <a
            href="/faq"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Вопросы-ответы
          </a>
          <a
            href="/policy"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Пользовательские соглашения
          </a>
          <a
            href="/"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            help@okauto.kz
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=77075001122"
            target="_blank"
            className="link hover-blue pointer mv2 flex flex-row"
          >
            <img alt="wp" title="wp" src={wp} className=" mr2" />
            Напишите на Whatsapp
          </a>
        </div>
        <div className="flex flex-column mt0-ns mt4">
          <h2 className="f1-l f2 b">Сервисы</h2>
          <a
            href="/insurance"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Страховка
          </a>
          <a
            href="/"
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Техпомощь
          </a>
          <a
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Премиум подписка <sup className="red">скоро</sup>
          </a>
          <a
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Автозапчасти <sup className="red">скоро</sup>
          </a>
          <a
            className="link hover-blue pointer mv2"
            onClick={this.props.toggleMenu}
          >
            Шины <sup className="red">скоро</sup>
          </a>
        </div>
      </div>,
      this.el
    )
  }
}
