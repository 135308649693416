import React, { Component } from "react"

import FaqModal from "./faqModal"
import { blueArrow } from "../assets"
import "./faqCard.css"

export default class FaqCard extends Component {
  state = {
    showMore: false,
  }

  toggleMore = () => {
    this.setState({ showMore: !this.state.showMore })
  }

  render() {
    return (
      <div
        className="faq__card__wrapper tl flex flex-column"
        id={this.props.id}
        ref={this.props.id}
      >
        <h1 className="f4 faq__card__header">{this.props.header}</h1>
        <div className="text__wrapper">
          <span>{this.props.text}</span>
          <div className="fadeout" />
        </div>

        <a
          className="blue f6 link flex pointer items-center"
          onClick={this.toggleMore}
        >
          Узнать подробно{" "}
          <img
            alt="blueArrow"
            title="blueArrow"
            src={blueArrow}
            className="mb0"
          />
        </a>

        {this.state.showMore ? (
          <FaqModal
            header={this.props.header}
            text={this.props.text}
            onClose={this.toggleMore}
          />
        ) : null}
      </div>
    )
  }
}
