import vk from "./vk.svg"
import wp from "./whatsapp.svg"
import yb from "./youtube.svg"
import ig from "./instagram.svg"
import fb from "./facebook.svg"
import ios from "./ios.svg"
import ios2 from "./ios2.svg"
import ios3 from "./ios3.svg"
import ios4 from "./ios4.svg"
import ad3 from "./ad3.svg"
import ad4 from "./ad4.svg"
import ad2 from "./ad2.svg"
import ad from "./android.svg"
import m2p from "./main2phones.png"
import menu from "./menu.svg"
import menuWhite from "./menuWhite.svg"
import close from "./close.svg"
import logo from "./logo.svg"
import proverka from "./proverka.png"
import proverkaMini from "./proverkaMini.png"
import tick from "./tick.svg"
import play from "./play.svg"
import info from "./info.svg"
import infoRed from "./infoRed.svg"
import receipt from "./receipt.svg"
import secure from "./secure.svg"
import easy from "./easy.svg"
import oplata from "./oplata.png"
import oplataMini from "./oplataMini.png"
import fiftyPercent from "./50.svg"
import camera from "./camera.svg"
import calendar from "./calendar.svg"
import push from "./push.svg"
import sms from "./sms.svg"
import notifications from "./notifications.png"
import notificationsMini from "./notificationsMini.png"
import like from "./like.svg"
import forbes from "./forbes.svg"
import star from "./star.svg"
import noOne from "./noOne.svg"
import bigTick from "./bigTick.svg"
import aboutPhones from "./aboutPhones.png"
import bgAbout from "./bgAbout.svg"
import yellowCircle from "./yellowCircle.svg"
import redCircle from "./redCircle.svg"
import greenCircle from "./greenCircle.svg"
import blueCircle from "./blueCircle.svg"
import okPhotoPhone from "./okPhotoPhone.png"
import photoCamera from "./photoCamera.png"
import iconCamera from "./iconCamera.svg"
import iconDoc from "./iconDoc.svg"
import iconTenge from "./iconTenge.svg"
import speed from "./speed.svg"
import bus from "./bus.svg"
import stop from "./stop.svg"
import radar from "./radar.svg"
import ultrasound from "./ultrasound.svg"
import sergek from "./sergek.png"
import agreementBgCircle1 from "./agreementBgCircle1.png"
import agreementBgCircle2 from "./agreementBgCircle2.png"
import agreementBgCircle3 from "./agreementBgCircle3.png"
import agreementBgCircle4 from "./agreementBgCircle4.png"
import agreementBgCircleMini from "./agreementBgCircleMini.png"
import okBusinessControl from "./okBusinessControl.png"
import countUp from "./countUp.svg"
import countDown from "./countDown.svg"
import carhiring from "./carhiring.svg"
import taxi from "./taxi.svg"
import otherOrg from "./otherOrg.svg"
import lesing from "./lesing.svg"
import payBusiness from "./payBusiness.svg"
import everydayNotification from "./everydayNotification.svg"
import receiptOfPayment from "./receiptOfPayment.svg"
import weAssist from "./weAssist.png"
import insurance1 from "./insurance1.png"
import insurance2 from "./insurance2.png"
import insurance3 from "./insurance3.png"
import smi1 from "./smi1.png"
import smi2 from "./smi2.png"
import smi3 from "./smi3.png"
import smi4 from "./smi4.png"
import smi5 from "./smi5.png"
import smi6 from "./smi6.png"
import smi1Mini from "./smi1Mini.png"
import smi2Mini from "./smi2Mini.png"
import smi3Mini from "./smi3Mini.png"
import smi4Mini from "./smi4Mini.png"
import smi5Mini from "./smi5Mini.png"
import smi6Mini from "./smi6Mini.png"
import leftArrow from "./leftArrow.svg"
import rightArrow from "./rightArrow.svg"
import vcru from "./vcru.svg"
import forbesSMI from "./forbesSMI.svg"
import expert from "./expert.png"
import capital from "./capital.svg"
import forklog from "./forklog.svg"
import steppe from "./steppe.svg"
import blueArrow from "./blueArrow.svg"
import partner1 from "./partner1.png"
import partner3 from "./partner3.png"
import gerb from "./gerb.svg"
import okLogoMenu from "./okLogoMenu.svg"
import arrowRight from "./arrowRight.svg"
import arrowRightGray from "./arrowRightGray.svg"
import arrowRightBlack from "./arrowRightBlack.svg"
import arrowLeftBlack from "./arrowLeftBlack.svg"
import amanat from "./amanat.jpg"
import amanatBanner from "./amanat-banner.jpg"
import freedomBanner from "./freedom-banner.jpg"
import freedom from "./freedom.jpg"
import freedomTwoMinute from "./freedom-two-minute.jpg"
import checkByAuto from "./check-by-auto.jpg"
import shickardBanner from "./shickardBanner.jpg"
import ecarPhoto from "./ecarPhoto.png"
import iosBannerTyres from "./iosBannerTyres.png"
import iosBannerMasterpass from "./iosBannerMasterpass.jpg"
import iosMasterpassBanner from "./iosMasterpassBanner.png"
import iosBannerTyresFebruary from "./iosBannerTyresFebruary.jpg"
import iosCarSubscription from "./iosCarSubscription.png"
import checkAutoPenaltiesBannerIos from "./checkAutoPenaltiesBannerIos.png"
import arrowBottom from "./arrowBottom.png"
import taxiPool from "./taxiPool.svg"
import leasing from "./leasing.svg"
import logistics from "./logistics.svg"
import others from "./otherCompanys.svg"
import calendar2 from "./calendar2.svg"
import bell from "./bell.svg"
import search from "./search.svg"
import integration from "./integration.svg"
import web from "./web.svg"
import controlling from "./controlling.svg"
import processing from "./processing.svg"
import search2 from "./search2.svg"
import paperYellow from "./paperYellow.svg"
import economy from "./economy.svg"
import manualChecking from "./manualCheck.svg"
import time from "./time.svg"
import pay from "./pay.svg"
import paper from "./paper.svg"
import car from "./car.svg"
import pc from "./pc.svg"
import notification from "./notification.svg"
import logoDHL from "./logoDHL.png"
import aMotors from "./aMotors.jpeg"
import arLineService from "./arLineService.jpg"
import anytimeLogoBlack from "./anytimeLogoBlack.png"
import clientPhoto1 from "./clientPhoto1.jpg"
import vkBlack from "./vk_b.svg"
import wpBlack from "./wp_b.svg"
import ybBlack from "./yt_b.svg"
import igBlack from "./ig_b.svg"
import fbBlack from "./fb_b.svg"
import whatsAppBtn from "./whatsAppBlack.svg"
import letter from "./letter.svg"
import okAutoLogoNew from "./okauto_logo_new.svg"
import okAutoLogoNewBlack from "./okauto_logo_new_black.svg"
import whatsAppWhite from "./whatsAppWhite.svg"
import whatsAppGreen from "./whatsAppGreen.svg"
import tickLarge from "./tickLarge.svg"
import closeNew from "./close_new.svg"
import tyre from "./tyre.png"
import paperOne from "./paper_one.svg"
import arrowDown from "./arrowDown.svg"
import eraseIcon from "./erase.svg"
import shoppingCart from "./shopping_cart.svg"
import successedImg from "./successed.svg"
import androidBannerChecks from "./androidBannerChecks.png"
import androidBannerInsurance from "./androidBannerInsurance.jpg"
import androidBannerTyres from "./androidBannerTyres.jpg"
import androidBannerMasterpass1 from "./androidBannerMasterpass1.png"
import androidBannerMasterpass2 from "./androidBannerMasterpass2.png"
import androidBannerCarParts from "./androidBannerCarParts.png"
import androidBannerCTOGramm from "./androidBannerCTOGramm.png"
import androidBannerCTOGrammBig from "./androidBannerCTOGrammBig.png"
import accident from "./accident_new.svg"
import eurasia from "./eurasia.png"
import freedomInsuranceLogo from "./logo_freedom.svg"
import amanatLogo from "./logo_amanat.svg"
import nomadLogo from "./logo_nomad.png"
import androidBannerUpdatePreview from "./androidBannerUpdatePreview.png"
import androidBannerMakingMats from "./androidBannerMakingMats.png"
import androidBannerMakingMatsBig from "./androidBannerMakingMatsBig.png"
import androidCheckTechInspection from "./androidCheckTechInspection.jpg"
import iosCheckTechInspection from "./iosCheckTechInspection.jpg"
import androidCheckPenaltiesAndGetProtocol from "./androidCheckPenaltiesAndGetProtocol.jpeg"
import iosCheckPenaltiesAndGetProtocol from "./iosCheckPenaltiesAndGetProtocol.jpeg"
import androidBannerCTO from "./androidBannerCTO.jpeg"
import androidBannerAutoMarket from "./androidBannerAutoMarket.jpg"

export {
  vk,
  wp,
  yb,
  ig,
  fb,
  ios,
  ios3,
  ios4,
  ad,
  ad3,
  ad4,
  m2p,
  menu,
  menuWhite,
  logo,
  proverka,
  tick,
  ios2,
  ad2,
  play,
  info,
  receipt,
  secure,
  easy,
  infoRed,
  oplata,
  fiftyPercent,
  calendar,
  camera,
  sms,
  push,
  notifications,
  notificationsMini,
  proverkaMini,
  oplataMini,
  noOne,
  star,
  like,
  forbes,
  bigTick,
  aboutPhones,
  bgAbout,
  yellowCircle,
  redCircle,
  greenCircle,
  blueCircle,
  okPhotoPhone,
  photoCamera,
  iconCamera,
  iconDoc,
  iconTenge,
  speed,
  stop,
  bus,
  ultrasound,
  radar,
  sergek,
  agreementBgCircle1,
  agreementBgCircle2,
  agreementBgCircle3,
  agreementBgCircle4,
  agreementBgCircleMini,
  close,
  okBusinessControl,
  countDown,
  countUp,
  taxi,
  carhiring,
  lesing,
  otherOrg,
  payBusiness,
  receiptOfPayment,
  everydayNotification,
  weAssist,
  insurance2,
  insurance1,
  insurance3,
  smi1,
  smi2,
  smi3,
  smi4,
  smi5,
  smi6,
  forbesSMI,
  forklog,
  expert,
  vcru,
  capital,
  steppe,
  leftArrow,
  rightArrow,
  smi1Mini,
  smi2Mini,
  smi3Mini,
  smi4Mini,
  smi5Mini,
  smi6Mini,
  blueArrow,
  partner1,
  partner3,
  gerb,
  okLogoMenu,
  arrowRight,
  arrowRightGray,
  arrowRightBlack,
  arrowLeftBlack,
  amanat,
  checkByAuto,
  amanatBanner,
  androidBannerChecks,
  androidBannerInsurance,
  androidBannerTyres,
  androidBannerMasterpass1,
  androidBannerMasterpass2,
  androidBannerCarParts,
  androidBannerCTOGramm,
  androidBannerCTOGrammBig,
  freedom,
  freedomBanner,
  freedomTwoMinute,
  shickardBanner,
  ecarPhoto,
  iosBannerTyres,
  iosMasterpassBanner,
  iosBannerTyresFebruary,
  iosBannerMasterpass,
  iosCarSubscription,
  checkAutoPenaltiesBannerIos,
  arrowBottom,
  taxiPool,
  leasing,
  logistics,
  others,
  calendar2,
  bell,
  search,
  search2,
  integration,
  web,
  controlling,
  processing,
  paperYellow,
  economy,
  manualChecking,
  time,
  pay,
  paper,
  car,
  pc,
  notification,
  logoDHL,
  anytimeLogoBlack,
  clientPhoto1,
  vkBlack,
  wpBlack,
  ybBlack,
  igBlack,
  fbBlack,
  whatsAppBtn,
  letter,
  okAutoLogoNew,
  okAutoLogoNewBlack,
  whatsAppWhite,
  whatsAppGreen,
  tickLarge,
  closeNew,
  arLineService,
  aMotors,
  tyre,
  paperOne,
  arrowDown,
  eraseIcon,
  shoppingCart,
  successedImg,
  accident,
  eurasia,
  freedomInsuranceLogo,
  amanatLogo,
  nomadLogo,
  androidBannerUpdatePreview,
  androidBannerMakingMats,
  androidBannerMakingMatsBig,
  androidCheckTechInspection,
  iosCheckTechInspection,
  androidCheckPenaltiesAndGetProtocol,
  iosCheckPenaltiesAndGetProtocol,
  androidBannerCTO,
  androidBannerAutoMarket
}
