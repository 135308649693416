import React from "react"

import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import "./smi.css"
import {
  vcru,
  expert,
  capital,
  forbesSMI,
  forklog,
  steppe,
  smi1,
  smi2,
  smi3,
  smi4,
  smi5,
  smi6,
  smi1Mini,
  smi2Mini,
  smi3Mini,
  smi4Mini,
  smi5Mini,
  smi6Mini,
} from "../assets"
const content = [
  {
    logo: vcru,
    description: `«Публичный питч: OKauto — казахстанское мобильное приложение для проверки 
    и оплаты штрафов ГИБДД»`,
    image: smi1,
    imageMini: smi1Mini,
    ref:
      "https://vc.ru/flood/35829-publichnyy-pitch-okauto-kazahstanskoe-mobilnoe-prilozhenie-dlya-proverki-i-oplaty-shtrafov-gibdd",
  },
  {
    logo: expert,
    description: `OKauto, казахстанское мобильное приложение для проверки и оплаты штрафов, стремится к миллиону скачиваний и планирует экспансию на соседние рынки`,
    image: smi2,
    imageMini: smi2Mini,
    ref: "https://expertonline.kz/a15885/",
  },
  {
    logo: capital,
    description: `Почему предприниматели в Казахстане должны быть «стенобоями»`,
    image: smi3,
    imageMini: smi3Mini,
    ref:
      "https://kapital.kz/business/68254/pochemu-predprinimateli-v-kazahstane-dolzhny-byt-stenoboyami.html",
  },
  {
    logo: forbesSMI,
    description: `Топ-30 мобильных приложений Казахстана`,
    image: smi4,
    imageMini: smi4Mini,
    ref:
      "https://forbes.kz/leader/top-30_mobilnyih_prilojeniy_kazahstana_-_2018_1539048058/",
  },
  {
    logo: forklog,
    description: `Автомобилисты Казахстана могут оплатить штрафы в биткоинах`,
    image: smi5,
    imageMini: smi5Mini,
    ref:
      "https://forklog.com/avtomobilisty-kazahstana-mogut-oplatit-shtrafy-v-bitkoinah/",
  },
  {
    logo: steppe,
    description: `Как мобильное приложение для водителей «OKauto» набрало 270 тыс. пользователей и вошло в топ-20 самых популярных в App Store`,
    image: smi6,
    imageMini: smi6Mini,
    ref:
      "https://the-steppe.com/news/business/2017-11-22/kak-mobilnoe-prilozhenie-dlya-voditeley-okauto-nabralo-270-tys-polzovateley-i-v-voshlo-v-top-20-samyh-populyarnyh-v-app-store",
  },
]

const SMI = () => {
  return (
    <div>
      <Slider
        className="slider-wrapper db-ns dn"
        infinite
        autoplay={3000}
        touchDisabled
      >
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{
              background: `url('${item.image}') no-repeat fixed`,
              backgroundPositionX: "center",
              backgroundSize: "contain",
            }}
          >
            <div className="inner">
              <img
                alt={"logo" + index}
                title={"logo" + index}
                src={item.logo}
                className="logo__style"
              />
              <p>{item.description}</p>
              <a
                className="link underline yellow pointer"
                href={item.ref}
                target="_blank"
              >
                Читать далее
              </a>
            </div>
          </div>
        ))}
      </Slider>
      <Slider
        className="slider-wrapper dn-ns"
        infinite
        autoplay={3000}
        touchDisabled
      >
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{
              background: `url('${item.imageMini}') no-repeat`,
              backgroundPositionX: "center",
              backgroundPositionY: "top",
              backgroundSize: "contain",
            }}
          >
            <div className="inner">
              <img
                alt={"logo" + index}
                title={"logo" + index}
                src={item.logo}
                className="logo__style"
              />
              <p>{item.description}</p>
              <a
                className="link underline yellow pointer"
                href={item.ref}
                target="_blank"
              >
                Читать далее
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default SMI
