import React from "react"
import { ios2, ad2 } from "../assets"
import "tachyons"
import "./downloadFrom.css"

const DownloadFrom = () => {
  return (
    <div className="flex justify-center justify-start-ns mb2">
      <a
        href="https://apps.apple.com/kz/app/okauto-%D1%88%D1%82%D1%80%D0%B0%D1%84%D1%8B-%D0%BF%D0%B4%D0%B4/id1218144486"
        className="flex f7 link ba items-center justify-center black download__from__button"
        target="_blank"
      >
        <img alt="ios2" title="ios2" src={ios2} className=" h2 w2 mb0 mr1" />
        <div className="flex flex-column mt2 mb1 mr3 tl">
          <span className="from__text">Скачать из</span>
          <span className="b mt1 store__text">App Store</span>
        </div>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=kz.psengineering.okauto"
        className="flex f7 link ba items-center justify-center ml2 black download__from__button"
        target="_blank"
      >
        <img alt="ad2" title="ad2" src={ad2} className=" h2 w2 mb0 mr2" />
        <div className="flex flex-column mt2 mb1 tl">
          <span className="from__text">Скачать из</span>
          <span className="b mt1 store__text">Google Play</span>
        </div>
      </a>
    </div>
  )
}

export default DownloadFrom
